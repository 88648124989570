import { useRouter } from 'next/router';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { FormikErrors, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import dayjs from 'dayjs';
import { ApolloError, useMutation } from '@apollo/client';
import { Button, Grid, InputAdornment, Typography } from '@mui/material';
import { Banner } from '@care/react-component-lib';
import { Schema } from 'yup';
import { DatePicker } from '@care/react-date-components';
import { SelectOption } from '@/components/shared/Select';
import Header from '@/components/shared/Header';
import ZipInput, { Location } from '@/components/shared/ZipInput';
import {
  useAppDispatch,
  useEnterpriseState,
  useFlowState,
  useUserState,
} from '@/components/AppState';
import HelpTooltip from '@/components/shared/HelpTooltip';
import HTMLSanitizer from '@/components/HTMLSanitizer';
import OverlaySpinner from '@/components/shared/OverlaySpinner';
import FormikInlineTextField from '@/components/shared/blocks/FormikInlineTextField';
import FormikInlineSelectField from '@/components/shared/blocks/FormikInlineSelectField';
import useClientCustomizations from '@/components/hooks/useClientCustomizations';
import useEnterKey from '@/components/hooks/useEnterKey';
import { VERIFY_ENTERPRISE_EMPLOYEE_ELIGIBILITY } from '@/components/request/GQL';
import {
  EventNames,
  ENTERPRISE_ROUTES,
  SKIP_AUTH_CONTEXT_KEY,
  TEXTS,
  FEATURE_FLAGS,
} from '@/constants';
import {
  verifyEnterpriseEmployeeEligibility,
  verifyEnterpriseEmployeeEligibility_verifySeekerEligibilityForEnterpriseEnrollment_EnterpriseEnrollmentProcessFailure_errors as verifyEligibilityErrors,
  verifyEnterpriseEmployeeEligibilityVariables,
} from '@/__generated__/verifyEnterpriseEmployeeEligibility';
import {
  EnterpriseEmployeeInformation,
  EnterpriseEnrollmentErrorStatus,
  EnterpriseErrorType,
} from '@/__generated__/globalTypes';
import { useEventLogger } from '@/utilities/analyticsHelper';
import { getEnterpriseEnrollmentComponents_getEnterpriseEnrollmentComponents_customizableComponents as CustomizableComponent } from '@/__generated__/getEnterpriseEnrollmentComponents';
import { EnterpriseState } from '@/types/enterprise';
import { getEnterpriseActions } from '@/state/enterprise/reducer';
import { EligibleFormValues } from '@/types/eligbility';
import { useFlag } from '@/context/FeatureFlagsContext';
import { SxClassProps } from '@/types/global';

const {
  LABELS: { EMPLOYEE_ID, EMAIL, FIRST_NAME, LAST_NAME, DATE_OF_BIRTH, STREET_ADDRESS, CONTINUE },
  ERRORS: {
    REQUIRED,
    ENTER_A_VALID_FIRST_NAME,
    ENTER_A_VALID_LAST_NAME,
    ENTER_A_VALID_EMAIL,
    SELECT_A_VALID_DATE,
    STREET_ADDRESS_CAN_NOT_EXCEED,
  },
  STATIC: {
    ELIGIBILITY: {
      HEADER,
      SSO_HEADER,
      ERROR_BANNER_HEADER,
      LOGGING: { PAGE_VARIANTS },
    },
  },
} = TEXTS;

const minDate = dayjs('1900-01-01', 'YYYY-MM-DD');
const maxDate = dayjs();

const classes: SxClassProps = {
  errorWarning: {
    marginTop: 2,
  },
  input: {
    marginBottom: 2,
  },
  inputContainer: (theme) => ({
    // TODO: fixes to avoid issues with color of disabled controls; use while monorepo team will not fix
    '& input:disabled + *': {
      pointerEvents: 'none',
      opacity: '0.5',
    },
    '& label.Mui-disabled#dateOfBirth-label': {
      color: theme.palette.care.grey[500],
    },
  }),
  submitButton: {
    width: { xs: '100%', md: '327px' },
    marginTop: 4,
  },
  submitButtonWrapper: {
    textAlign: 'center',
  },
  select: {
    width: '100%',
    marginBottom: 2,
    height: '64px',
  },
};

export function getValidationSchema(
  customizableComponents: {
    [key: string]: CustomizableComponent | undefined;
  },
  isDivisionEnabled?: boolean
): Schema<any> {
  const { emailComponent, employeeIdComponent, rolesComponent } = customizableComponents;
  const shouldEmployeeIDBeHandled = Boolean(employeeIdComponent);
  const shouldEmailBeHandled = Boolean(!employeeIdComponent && emailComponent);
  const roleOptions =
    rolesComponent?.roleOptions?.map(({ label, id }) => ({ label, value: id })) ?? [];
  const employeeIdScmema = shouldEmployeeIDBeHandled
    ? { employeeId: yup.string().required(REQUIRED) }
    : {};
  const emailScmema = shouldEmailBeHandled
    ? { email: yup.string().email(ENTER_A_VALID_EMAIL).required(REQUIRED) }
    : {};
  const rolesScmema = rolesComponent
    ? {
        role: yup
          .string()
          .oneOf(roleOptions.map((option) => option.value))
          .required(REQUIRED),
      }
    : {};

  const divisionSchema = isDivisionEnabled ? { division: yup.string().required(REQUIRED) } : {};

  const validationSchema = yup.object({
    firstName: yup.string().min(2, ENTER_A_VALID_FIRST_NAME).required(REQUIRED),
    lastName: yup.string().min(2, ENTER_A_VALID_LAST_NAME).required(REQUIRED),
    dateOfBirth: yup
      .mixed()
      .test({
        name: 'dateOfBirthMinCheck',
        message: SELECT_A_VALID_DATE,
        test(value: unknown) {
          if (!value || !dayjs.isDayjs(value)) {
            return false;
          }
          return value.isAfter(minDate);
        },
      })
      .test({
        name: 'dateOfBirthMaxCheck',
        message: SELECT_A_VALID_DATE,
        test(value: unknown) {
          if (!value || !dayjs.isDayjs(value)) {
            return false;
          }
          return value.isBefore(maxDate);
        },
      })
      .required(REQUIRED),
    address: yup.string().max(55, STREET_ADDRESS_CAN_NOT_EXCEED).required(REQUIRED),
    location: yup
      .object({
        zipcode: yup.string().required(REQUIRED),
        city: yup.string().required(REQUIRED),
        state: yup.string().required(REQUIRED),
      })
      .required(REQUIRED),
    ...employeeIdScmema,
    ...emailScmema,
    ...rolesScmema,
    ...divisionSchema,
  });
  return validationSchema;
}

export default function ConfirmEligibility() {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { safetyError } = useFlowState();
  const userState = useUserState();
  const enterpriseState = useEnterpriseState();
  const isLifeCareCompany = useFlag(FEATURE_FLAGS.ENT_LIFECARE_TRANSITION_FLAG.key);
  const isDivDropdownEnabled = useFlag(FEATURE_FLAGS.ENT_ENROLLMENT_DIV_DROPDOWN.key);
  const { getGroupShortName, getField } = useClientCustomizations();
  const emailComponent = getField('email');
  const employeeIdComponent = getField('employeeId');
  const rolesComponent = getField('roles');
  const validationSchema = getValidationSchema(
    {
      emailComponent,
      employeeIdComponent,
      rolesComponent,
    },
    isDivDropdownEnabled
  );
  const shouldEmployeeIDBeHandled = Boolean(employeeIdComponent);
  const shouldEmailBeHandled = Boolean(!employeeIdComponent && emailComponent);
  const groupShortName = getGroupShortName();
  const [errorMessage, setErrorMessage] = useState(enterpriseState.error || '');
  const [isSubmitting, setSubmitting] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);

  const eligibilityEmailTooltip = emailComponent && emailComponent.tooltip && (
    <HTMLSanitizer html={emailComponent.tooltip} />
  );
  const eligibilityEmployeeIdTooltip = employeeIdComponent && employeeIdComponent.tooltip && (
    <HTMLSanitizer html={employeeIdComponent.tooltip} />
  );
  const roleOptions =
    rolesComponent?.roleOptions?.map(({ label, id }) => ({ label, value: id })) ?? [];

  const divDropdownOptions: SelectOption[] = [
    { label: 'Call Center', value: 'Call Center' },
    { label: 'Corporate', value: 'Corporate' },
    { label: 'Distribution Center', value: 'Distribution Center' },
    { label: 'Field Services', value: 'Field Services' },
    { label: 'Store', value: 'Store' },
    { label: 'Other', value: 'Other' },
  ];

  const pageVariant = PAGE_VARIANTS.NetNewUser;

  const logger = useEventLogger({
    user_flow: 'Enrollment',
    page_variant: pageVariant,
  });

  const { updateEnterpriseState } = getEnterpriseActions(dispatch);
  useEffect(() => {
    if (safetyError) {
      setErrorMessage(safetyError);
      setIsBlocked(true);
    }
  }, [safetyError]);

  useEffect(() => {
    logger({ name: EventNames.SCREEN_VIEWED });
  }, []);

  const updateAppState = (values: EligibleFormValues) => {
    let dataToUpdate = {} as Partial<EnterpriseState>;

    if (values.employeeId) {
      dataToUpdate = { ...dataToUpdate, employeeId: values.employeeId };
    }
    if (values.email) {
      dataToUpdate = { ...dataToUpdate, email: values.email };
    }
    if (values.role) {
      dataToUpdate = { ...dataToUpdate, role: values.role };
    }
    if (values.division) {
      dataToUpdate = { ...dataToUpdate, division: values.division };
    }

    dataToUpdate = {
      ...dataToUpdate,
      firstName: values.firstName,
      lastName: values.lastName,
      dateOfBirth: values.dateOfBirth!.format('YYYY-MM-DD'),
      address: values.address,
      location: values.location,
      error: undefined,
    };

    updateEnterpriseState(dataToUpdate);
  };

  const sendTooltipAmplitudeEvent = (tipLocation: string, content: ReactElement | string) => {
    let message = '';
    if (typeof content === 'string') {
      message = content;
    } else {
      message = content.props.html.replace(/(<([^>]+)>)/gi, '').toString();
    }
    logger({
      name: EventNames.TOOL_TIP_INTERACTED,
      data: {
        tip_location: tipLocation,
        message,
      },
    });
  };

  const [verifyEligibility, { data: verifyEligibilityResponse, error: verifyEligibilityError }] =
    useMutation<verifyEnterpriseEmployeeEligibility, verifyEnterpriseEmployeeEligibilityVariables>(
      VERIFY_ENTERPRISE_EMPLOYEE_ELIGIBILITY,
      {
        context: { [SKIP_AUTH_CONTEXT_KEY]: true },
      }
    );

  const handleSubmission = async (values: EligibleFormValues) => {
    setSubmitting(true);
    updateAppState(values);

    logger({ name: EventNames.ENROLLMENT_FORM_CTA_CLICKED_DEPRECATED });

    const employeeInformation: EnterpriseEmployeeInformation = {
      firstName: values.firstName,
      lastName: values.lastName,
      dateOfBirth: values.dateOfBirth!.format('YYYY-MM-DD'),
      address: values.address,
      zip: values.location.zipcode,
    };

    const employeeIdValue = values.employeeId || userState.employeeId;
    const emailValue = userState.email || values.email;
    if (employeeIdValue) employeeInformation.employeeId = employeeIdValue;
    if (emailValue) employeeInformation.email = emailValue;
    if (rolesComponent) {
      employeeInformation.role = values.role;
    }
    if (isDivDropdownEnabled) {
      employeeInformation.division = values.division;
    }

    await verifyEligibility({
      variables: {
        input: {
          group: groupShortName,
          employeeInformation,
        },
      },
    });
  };

  const initialValues: EligibleFormValues = {
    firstName: enterpriseState.firstName || userState.firstName || '',
    lastName: enterpriseState.lastName || userState.lastName || '',
    address: enterpriseState.address,
    location: {
      zipcode: enterpriseState.location?.zipcode || '',
      city: enterpriseState.location?.city || '',
      state: enterpriseState.location?.state || '',
    },
    dateOfBirth: null,
  };

  if (shouldEmployeeIDBeHandled) {
    initialValues.employeeId = isLifeCareCompany
      ? enterpriseState.employeeId || ''
      : userState.employeeId;
  }

  if (shouldEmailBeHandled) {
    initialValues.email = userState.email || enterpriseState.email;
  }

  if (rolesComponent) {
    initialValues.role = userState.role?.id ?? '';
  }

  if (isDivDropdownEnabled) {
    initialValues.division = enterpriseState.division || '';
  }

  const formik = useFormik<EligibleFormValues>({
    initialValues,
    validationSchema,
    onSubmit: handleSubmission,
  });

  useEffect(() => {
    formik.validateForm();
  }, []);

  function handleGlobalError(name: string, message: string) {
    logger({
      name: EventNames.ERROR_VIEWED,
      data: {
        error_type: name,
        message,
      },
    });
    setErrorMessage(message);
  }

  function handleFieldError(errors: verifyEligibilityErrors[]) {
    errors.forEach(({ errorMessages: [firstError] }) => {
      logger({
        name: EventNames.ERROR_VIEWED,
        data: {
          error_type: 'Form Validation Error',
          message: firstError,
        },
      });
    });
    const errorsObj = errors.reduce(
      (obj, error) => ({
        ...obj,
        [error.fieldName!]: error.errorMessages[0],
      }),
      {} as FormikErrors<EligibleFormValues>
    );
    formik.setErrors(errorsObj);
  }

  async function handleMemberExistsError(error: verifyEligibilityErrors) {
    logger({
      name: EventNames.MEMBER_EXISTS,
      data: { message: error.errorMessages[0] },
    });
    await router.push(`/${router.query.groupName}${ENTERPRISE_ROUTES.CONNECT_ACCOUNT}`);
  }

  function handleCommonErrors(errors: verifyEligibilityErrors[]) {
    const globalError = errors.find(
      (error: verifyEligibilityErrors) => error.errorType === EnterpriseErrorType.GLOBAL
    );

    if (globalError) handleGlobalError('Eligibility Failure', globalError.errorMessages[0]);
    else handleFieldError(errors);
  }

  function handleSafetyFailureError(error: verifyEligibilityErrors) {
    const safetyFailureErrorMessage = error.errorMessages[0];
    logger({
      name: EventNames.BLOCKLIST_MATCH,
      data: { message: safetyFailureErrorMessage },
    });
    dispatch({
      type: 'setSafetyError',
      safetyError: safetyFailureErrorMessage,
    });
  }

  async function handleVerifyCompletion(response: verifyEnterpriseEmployeeEligibility) {
    const { verifySeekerEligibilityForEnterpriseEnrollment: res } = response;
    if (res.__typename === 'EnterpriseEmployeeEligibilitySuccess' && res.success) {
      await router.push(`/${router.query.groupName}${ENTERPRISE_ROUTES.ACCOUNT_CREATION}`);
      return;
    }

    if (res.__typename === 'EnterpriseEnrollmentProcessFailure') {
      switch (res.errorStatus) {
        case EnterpriseEnrollmentErrorStatus.BLOCKLIST_MATCH:
          handleSafetyFailureError(res.errors[0]);
          break;
        case EnterpriseEnrollmentErrorStatus.MEMBER_EXISTS:
          await handleMemberExistsError(res.errors[0]);
          break;
        default:
          handleCommonErrors(res.errors);
      }
      setSubmitting(false);
    }
  }

  function handleVerifyError(graphQLError: ApolloError) {
    setErrorMessage(graphQLError.message);
    setSubmitting(false);
  }

  // Process GraphQL success
  useEffect(() => {
    if (verifyEligibilityResponse) handleVerifyCompletion(verifyEligibilityResponse);
  }, [verifyEligibilityResponse]);

  // Process GraphQL error
  useEffect(() => {
    if (verifyEligibilityError) handleVerifyError(verifyEligibilityError);
  }, [verifyEligibilityError]);

  const handleSubmit = useCallback(() => {
    formik.handleSubmit();
  }, []);

  // TODO: figure out a proper way to handle zip errors
  const [zipError, setZipError] = useState(true);
  const onZipInputChange = useCallback((newLocation: Location) => {
    formik.setFieldValue('location', newLocation);
  }, []);
  const onZipInputError = useCallback((e: boolean) => {
    setZipError(e);
  }, []);
  useEffect(() => {
    formik.validateField('location');
  }, [formik.values.location]);

  const isSubmitButtonDisabled = isBlocked || isSubmitting || zipError;
  useEnterKey(!isSubmitButtonDisabled, handleSubmit);

  // datapicker handlers
  const onDateOfBirthSelect = useCallback((date: dayjs.Dayjs | null) => {
    formik.setFieldValue('dateOfBirth', date);
  }, []);
  const onDateOfBirthBlur = useCallback(() => {
    formik.setFieldTouched('dateOfBirth', true);
  }, []);
  const showDoBError = formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth);

  const getHeaderValue = (): string => {
    if (userState.ssoPartnerInfoId) {
      return SSO_HEADER;
    }
    return HEADER;
  };

  return (
    <>
      <OverlaySpinner isOpen={isSubmitting} isTransparent />
      <FormikProvider value={formik}>
        <Grid container>
          <Grid item xs={12}>
            <Header>{getHeaderValue()}</Header>
          </Grid>
          <Grid item xs={12} sx={classes.errorWarning}>
            {errorMessage && (
              /* id is needed for testing purposes */
              <div id="errorBanner">
                <Banner type="warning" roundCorners fullWidth>
                  <Typography variant="h4" color="textPrimary">
                    {ERROR_BANNER_HEADER}
                  </Typography>
                  <Typography variant="body3" color="textPrimary">
                    <HTMLSanitizer html={errorMessage} />
                  </Typography>
                </Banner>
              </div>
            )}
          </Grid>
          {shouldEmployeeIDBeHandled && (
            <Grid item xs={12} sx={classes.inputContainer}>
              <FormikInlineTextField
                id="employeeId"
                name="employeeId"
                sx={classes.input}
                label={employeeIdComponent!.label || EMPLOYEE_ID}
                disabled={isBlocked}
                {...(eligibilityEmployeeIdTooltip
                  ? {
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <HelpTooltip
                              testId="eligibility-tooltip"
                              onOpen={() => {
                                sendTooltipAmplitudeEvent(
                                  'Eligibility Field',
                                  eligibilityEmployeeIdTooltip
                                );
                              }}
                              content={eligibilityEmployeeIdTooltip}
                            />
                          </InputAdornment>
                        ),
                      },
                    }
                  : {})}
              />
            </Grid>
          )}
          {shouldEmailBeHandled && (
            <Grid item xs={12} sx={classes.inputContainer}>
              <FormikInlineTextField
                id="email"
                name="email"
                sx={classes.input}
                label={emailComponent!.label || EMAIL}
                disabled={isBlocked}
                {...(eligibilityEmailTooltip
                  ? {
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <HelpTooltip
                              testId="eligibility-tooltip"
                              onOpen={() => {
                                sendTooltipAmplitudeEvent(
                                  'Eligibility Field',
                                  eligibilityEmailTooltip
                                );
                              }}
                              content={eligibilityEmailTooltip}
                            />
                          </InputAdornment>
                        ),
                      },
                    }
                  : {})}
              />
            </Grid>
          )}
          {isDivDropdownEnabled && (
            <Grid item xs={12} sx={classes.inputContainer}>
              <FormikInlineSelectField
                id="division"
                name="division"
                label="Division"
                options={divDropdownOptions}
                multiple={false}
                autoWidth={false}
                native={false}
              />
            </Grid>
          )}
          <Grid item xs={12} sx={classes.inputContainer}>
            <FormikInlineTextField
              id="firstName"
              name="firstName"
              sx={classes.input}
              label={FIRST_NAME}
              disabled={isBlocked}
            />
          </Grid>
          <Grid item xs={12} sx={classes.inputContainer}>
            <FormikInlineTextField
              id="lastName"
              name="lastName"
              sx={classes.input}
              label={LAST_NAME}
              disabled={isBlocked}
            />
          </Grid>
          {rolesComponent && (
            <Grid item xs={12} sx={classes.inputContainer}>
              <FormikInlineSelectField
                id="role"
                name="role"
                sx={classes.input}
                label={rolesComponent.label}
                options={roleOptions}
                disabled={isBlocked}
                multiple={false}
                autoWidth={false}
                native={false}
              />
            </Grid>
          )}
          <Grid item xs={12} sx={classes.inputContainer}>
            <DatePicker
              textFieldProps={{
                id: 'dateOfBirth',
                placeholder: 'mm/dd/yyyy',
                error: showDoBError,
                helperText: showDoBError ? formik.errors.dateOfBirth : undefined,
              }}
              name="dateOfBirth"
              label={DATE_OF_BIRTH}
              format="MM/DD/YYYY"
              sx={classes.input}
              value={formik.values.dateOfBirth}
              onChange={onDateOfBirthSelect}
              onBlur={onDateOfBirthBlur}
              disablePast={false}
              disableFuture
              minDate={minDate}
              maxDate={maxDate}
              disabled={isBlocked}
              view="year"
              views={['year', 'month', 'day']}
            />
          </Grid>
          <Grid item xs={12} sx={classes.inputContainer}>
            <FormikInlineTextField
              id="address"
              name="address"
              sx={classes.input}
              label={STREET_ADDRESS}
              disabled={isBlocked}
            />
          </Grid>
          <Grid item xs={12} sx={classes.inputContainer}>
            <ZipInput
              location={formik.values.location}
              onChange={onZipInputChange}
              onError={onZipInputError}
              disabled={isBlocked}
            />
          </Grid>
          <Grid item xs={12} sx={classes.submitButtonWrapper}>
            <Button
              id="continue"
              color="secondary"
              variant="contained"
              size="large"
              sx={classes.submitButton}
              onClick={handleSubmit}
              disabled={isSubmitButtonDisabled}>
              {CONTINUE}
            </Button>
          </Grid>
        </Grid>
      </FormikProvider>
    </>
  );
}
