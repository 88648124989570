import React from 'react';
import { FieldConfig, useField } from 'formik';
import { InlineTextField, InlineTextFieldProps } from '@care/react-component-lib';

type FormikInlineTextFieldProps = Omit<InlineTextFieldProps, 'ref'> & FieldConfig<string>;

function FormikInlineTextField(props: FormikInlineTextFieldProps) {
  const { name, validate, ...rest } = props;
  const [field, meta] = useField({ name, validate });
  const { helperText, error } = props;
  const showError = meta.touched && Boolean(meta.error);

  return (
    <InlineTextField
      {...field}
      {...rest}
      error={error || showError}
      helperText={showError ? meta.error : helperText}
    />
  );
}

FormikInlineTextField.defaultProps = {
  helperText: undefined,
};

export default FormikInlineTextField;
