/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Possible outcomes from enrollment conflict check
 */
export enum EnrollmentConflict {
  EXISTING_WPS_ACCOUNT = "EXISTING_WPS_ACCOUNT",
  MEMBER_EXISTS_NO_PREM_ACCESS = "MEMBER_EXISTS_NO_PREM_ACCESS",
  NO_CONFLICT = "NO_CONFLICT",
}

/**
 * List of error statuses that can cause enrollment failure for enterprise client
 */
export enum EnterpriseEnrollmentErrorStatus {
  ACCOUNT_LOCKED_ERROR = "ACCOUNT_LOCKED_ERROR",
  BLOCKLIST_MATCH = "BLOCKLIST_MATCH",
  ENT_INELIGIBLE_EMAIL = "ENT_INELIGIBLE_EMAIL",
  FAIL = "FAIL",
  INVALID_CREDENTIALS = "INVALID_CREDENTIALS",
  MEMBER_EXISTS = "MEMBER_EXISTS",
}

/**
 * Enterprise Enrollment Statuses
 */
export enum EnterpriseEnrollmentStatus {
  ACTIVE = "ACTIVE",
  GROUP_LIMIT_REACHED = "GROUP_LIMIT_REACHED",
  INACTIVE = "INACTIVE",
  INVALID = "INVALID",
  PENDING = "PENDING",
  PENDING_EMAIL_VERIFICATION = "PENDING_EMAIL_VERIFICATION",
  PENDING_EMAIL_VERIFICATION_EXPIRED = "PENDING_EMAIL_VERIFICATION_EXPIRED",
  PENDING_UPDATE_EMAIL = "PENDING_UPDATE_EMAIL",
}

/**
 * The types of error we can get from the enterprise APIs
 */
export enum EnterpriseErrorType {
  FIELD = "FIELD",
  GLOBAL = "GLOBAL",
}

/**
 * Service Ids, for services which seeker might be interested in.
 */
export enum EnterpriseOnboardingServiceIds {
  CHILDCARE = "CHILDCARE",
  HOUSEKEEP = "HOUSEKEEP",
  PETCAREX = "PETCAREX",
  SENIRCARE = "SENIRCARE",
  SPECIALNEEDS = "SPECIALNEEDS",
  TUTORING = "TUTORING",
}

/**
 * type of service provided by the caregiver
 * will be adding more services as required
 */
export enum ServiceType {
  AU_PAIR = "AU_PAIR",
  CARE_GIGS = "CARE_GIGS",
  CHILD_CARE = "CHILD_CARE",
  GENERIC = "GENERIC",
  HOUSEKEEPING = "HOUSEKEEPING",
  PET_CARE = "PET_CARE",
  SENIOR_CARE = "SENIOR_CARE",
  SPECIAL_NEEDS = "SPECIAL_NEEDS",
  TUTORING = "TUTORING",
}

/**
 * U.S. State Codes
 */
export enum UsStateCode {
  AK = "AK",
  AL = "AL",
  AR = "AR",
  AZ = "AZ",
  CA = "CA",
  CO = "CO",
  CT = "CT",
  DC = "DC",
  DE = "DE",
  FE = "FE",
  FL = "FL",
  GA = "GA",
  HI = "HI",
  IA = "IA",
  ID = "ID",
  IL = "IL",
  IN = "IN",
  KS = "KS",
  KY = "KY",
  LA = "LA",
  MA = "MA",
  MD = "MD",
  ME = "ME",
  MI = "MI",
  MN = "MN",
  MO = "MO",
  MS = "MS",
  MT = "MT",
  NC = "NC",
  ND = "ND",
  NE = "NE",
  NH = "NH",
  NJ = "NJ",
  NM = "NM",
  NV = "NV",
  NY = "NY",
  OH = "OH",
  OK = "OK",
  OR = "OR",
  PA = "PA",
  PR = "PR",
  RI = "RI",
  SC = "SC",
  SD = "SD",
  TN = "TN",
  TX = "TX",
  UT = "UT",
  VA = "VA",
  VT = "VT",
  WA = "WA",
  WI = "WI",
  WV = "WV",
  WY = "WY",
}

/**
 * Input for isActiveMemberForGroup mutation
 */
export interface EnrollmentIsActiveMemberForGroupInput {
  employeeId: string;
  groupShortName: string;
}

/**
 * Input to verify if there are any enrollment conflicts
 */
export interface EnterpriseCheckEnrollmentConflictByEmailInput {
  email: string;
  groupShortName: string;
}

/**
 * Input for EligibilityVerification
 */
export interface EnterpriseEmployeeEnrollmentDetails {
  employeeInformation: EnterpriseEmployeeInformation;
  group: string;
}

/**
 * Input for the Employee releated Information for enterprise enrollment
 */
export interface EnterpriseEmployeeInformation {
  address: string;
  dateOfBirth?: globalScalarLocalDate | null;
  division?: string | null;
  email?: string | null;
  employeeId?: string | null;
  firstName: string;
  googleAuthIdToken?: string | null;
  lastName: string;
  lifeCareMemberId?: string | null;
  optForEmail?: boolean | null;
  password?: string | null;
  phoneNumber?: globalScalarPhoneNumber | null;
  primaryService?: ServiceType | null;
  role?: string | null;
  zip: string;
}

/**
 * Input for enterpriseEnrollmentEmployeeVerification
 */
export interface EnterpriseEnrollmentEmployeeVerificationInput {
  employeeVerificationId: string;
  groupShortName: string;
}

/**
 * Input for EnterpriseLifeCareLogin
 */
export interface EnterpriseLifeCareLoginInput {
  password: string;
  userId: string;
}

/**
 * Input type, for saving the services employee of an enterprise client is interested in.
 */
export interface EnterpriseSeekerServiceInterests {
  serviceIds?: (EnterpriseOnboardingServiceIds | null)[] | null;
}

/**
 * Input to verify an email domain from an email is valid
 * for the Care group
 */
export interface EnterpriseVerifyEmailDomainInput {
  email: string;
  groupShortName: string;
}

/**
 * Input to verify if employee exists
 */
export interface EnterpriseVerifyEmployeeExistsByEmailInput {
  email: string;
  groupShortName: string;
}

/**
 * Input for the LifeCare account verification query
 */
export interface LifeCareAccountVerificationInput {
  email?: string | null;
  employeeId?: string | null;
  groupShortName: string;
}

/**
 * Input for sending one time password to a LifeCare member
 */
export interface LifeCareOneTimePasswordInput {
  email: string;
  groupShortName: string;
}

/**
 * Input fields for change member's password.
 */
export interface MemberChangePasswordInput {
  password: string;
}

/**
 * Input for fetching the SSO based enrollment information of partner
 */
export interface SSOParterEnrollmentInput {
  clientName: string;
  ssoPartnerInfoId: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
