import { gql } from '@apollo/client';

const GET_ZIP_CODE_SUMMARY = gql`
  query getZipcodeSummaryByZip($zipcode: String!) {
    getZipcodeSummaryByZip(zipcode: $zipcode) {
      ... on ZipcodeSummary {
        city
        state
        zipcode
      }

      ... on InvalidZipcodeError {
        message
      }
    }
  }
`;

const GET_ENTERPRISE_ENROLLMENT_COMPONENTS = gql`
  query getEnterpriseEnrollmentComponents($group: String!) {
    getEnterpriseEnrollmentComponents(group: $group) {
      groupShortName
      requirePassword
      enrollmentDisclaimer
      customizableComponents {
        fieldName
        label
        tooltip
        roleOptions {
          id
          label
        }
      }
    }
  }
`;

const GET_ENTERPRISE_LIFE_CARE_ACCOUNT_VERIFICATION = gql`
  query enterpriseLifeCareAccountVerification($input: LifeCareAccountVerificationInput!) {
    enterpriseLifeCareAccountVerification(input: $input) {
      ... on LifeCareAccountVerificationSuccess {
        hasRegisteredLifeCareAccount
      }
      ... on EnterpriseFailureResponse {
        knownErrors {
          message
        }
      }
    }
  }
`;

const VERIFY_ENTERPRISE_EMPLOYEE_ELIGIBILITY = gql`
  mutation verifyEnterpriseEmployeeEligibility($input: EnterpriseEmployeeEnrollmentDetails!) {
    verifySeekerEligibilityForEnterpriseEnrollment(employeeEnrollmentDetails: $input) {
      ... on EnterpriseEmployeeEligibilitySuccess {
        success
      }
      ... on EnterpriseEnrollmentProcessFailure {
        errorStatus
        errors {
          errorType
          fieldName
          errorMessages
        }
      }
    }
  }
`;

const SAVE_ENTERPRISE_SEEKER_SERVICE_INTERESTS = gql`
  mutation saveEnterpriseSeekerServiceInterests($input: EnterpriseSeekerServiceInterests!) {
    saveEnterpriseSeekerServiceInterests(seekerInterests: $input) {
      ... on EnterpriseSaveSeekerInterestsSuccess {
        success
      }
    }
  }
`;

const ENTERPRISE_CHECK_ENROLLMENT_CONFLICT_BY_EMAIL = gql`
  query checkEnrollmentConflictByEmail($input: EnterpriseCheckEnrollmentConflictByEmailInput!) {
    enterpriseCheckEnrollmentConflictByEmail(input: $input) {
      ... on EnterpriseCheckEnrollmentConflictByEmailSuccess {
        __typename
        outcome
      }
      ... on EnterpriseFailureResponse {
        __typename
        knownErrors {
          message
        }
      }
    }
  }
`;

const VERIFY_EMPLOYEE_EXISTS_BY_EMAIL = gql`
  query verifyEmployeeExistsByEmail($input: EnterpriseVerifyEmployeeExistsByEmailInput!) {
    enterpriseVerifyEmployeeExistsByEmail(input: $input) {
      ... on EnterpriseVerifyEmployeeExistsByEmailSuccess {
        __typename
        memberExists
      }
      ... on EnterpriseFailureResponse {
        __typename
        knownErrors {
          message
        }
      }
    }
  }
`;
const ENROLL_ENTERPRISE_EMPLOYEE = gql`
  mutation enrollEnterpriseEmployee($input: EnterpriseEmployeeEnrollmentDetails!) {
    enrollSeekerForEnterpriseClient(employeeEnrollmentDetails: $input) {
      ... on EnterpriseEmployeeEnrollmentSuccess {
        authToken
        memberId
        wpsEnrollmentStatus
        voluntaryBenefitCareMembershipEligible
      }
      ... on EnterpriseEnrollmentProcessFailure {
        errorStatus
        errors {
          errorType
          fieldName
          errorMessages
        }
      }
    }
  }
`;

const ENROLL_EXISTING_SEEKER_TO_ENTERPRISE_CLIENT = gql`
  mutation enrollExistingSeekerToEnterpriseClient($input: EnterpriseEmployeeEnrollmentDetails!) {
    enrollExistingSeekerToEnterpriseClient(employeeEnrollmentDetails: $input) {
      ... on EnterpriseEmployeeEnrollmentSuccess {
        authToken
        memberId
        wpsEnrollmentStatus
        voluntaryBenefitCareMembershipEligible
      }
      ... on EnterpriseEnrollmentProcessFailure {
        errorStatus
        errors {
          errorType
          fieldName
          errorMessages
        }
      }
    }
  }
`;

const GET_ENTERPRISE_BRANDED_CONTENT = gql`
  query getEnterpriseBrandedContent($group: String!) {
    getEnterpriseBrandedContent(group: $group) {
      clientContactInformation {
        email
        phoneNumber
      }
      cobrandLogo {
        altText
        clickable
        imageURL
      }
      customizedFooterContent
    }
  }
`;

const GET_SSO_DATA = gql`
  query getEnterpriseSSOPartnerEnrollmentDetails($input: SSOParterEnrollmentInput!) {
    getEnterpriseSSOPartnerEnrollmentDetails(ssoPartnerEnrollmentInput: $input) {
      firstName
      lastName
      email
      employeeId
      role {
        id
        label
      }
      zipCode
      dateOfBirth
      addressLine1
      city
      state
      careGroupInfo {
        uuid
        name
        shortName
      }
      lifeCareMemberId
    }
  }
`;

const IS_ACTIVE_MEMBER_FOR_GROUP = gql`
  query isActiveMemberForGroup($input: EnrollmentIsActiveMemberForGroupInput!) {
    isActiveMemberForGroup(input: $input) {
      ... on EnrollmentIsActiveMemberForGroupSuccess {
        memberExists
      }
      ... on EnrollmentIsActiveMemberForGroupFailure {
        errors {
          message
        }
      }
    }
  }
`;

const LIFE_CARE_LOGIN = gql`
  query enterpriseLifeCareLogin($input: EnterpriseLifeCareLoginInput!) {
    enterpriseLifeCareLogin(input: $input) {
      __typename
      ... on EnterpriseLifeCareLoginSuccess {
        email
        firstName
        lastName
        lifeCareMemberId
        address {
          addressLine1
          addressLine2
          city
          state
          zip
        }
      }
      ... on EnterpriseFailureResponse {
        rawErrorMessage
        knownErrors {
          message
        }
      }
    }
  }
`;

const LIFE_CARE_OTP_CREATE = gql`
  mutation enterpriseLifeCareOTPCreate($input: LifeCareOneTimePasswordInput!) {
    enterpriseLifeCareOTPCreate(input: $input) {
      ... on LifeCareOneTimePasswordResponse {
        success
      }
    }
  }
`;

const FIND_CARE_GROUPS_BY_PARTIAL_NAME = gql`
  query findCareGroupsByPartialName($partialName: String!, $numResults: Int) {
    findCareGroupsByPartialName(partialName: $partialName, numResults: $numResults) {
      ... on CareGroupInfo {
        name
        shortName
        ssodirectLoginEnabled
        ssoConnectionPath
        url
      }
    }
  }
`;

const CHANGE_MEMBER_PASSWORD = gql`
  mutation changeMemberPassword($passwordInput: MemberChangePasswordInput!) {
    memberChangePassword(input: $passwordInput) {
      __typename
      ... on MemberChangePasswordSuccess {
        authToken
      }
      ... on MemberChangePasswordError {
        errors {
          __typename
          message
        }
      }
    }
  }
`;

const GET_ENTERPRISE_EMPLOYEE_VERIFICATION_RECORD = gql`
  query EnterpriseEnrollmentEmployeeVerification(
    $input: EnterpriseEnrollmentEmployeeVerificationInput!
  ) {
    enterpriseEnrollmentEmployeeVerification(input: $input) {
      __typename
      ... on EnterpriseEnrollmentEmployeeVerificationSuccess {
        employeeVerification {
          employeeId
          emailAddress
          firstName
          lastName
          careGroupName
        }
      }
      ... on EnterpriseFailureResponse {
        rawErrorMessage
        knownErrors {
          message
        }
      }
    }
  }
`;

const GET_ENTERPRISE_COUNTRY_OPTIONS = gql`
  query enterpriseCountryOptions($groupShortName: String!) {
    enterpriseCountryOptions(groupShortName: $groupShortName) {
      __typename
      ... on EnterpriseCountryOptionsSuccess {
        __typename
        countryOptions {
          countryCode
          url
        }
      }
      ... on EnterpriseFailureResponse {
        __typename
        rawErrorMessage
        knownErrors {
          message
        }
      }
    }
  }
`;

const GET_ENTERPRISE_MEMBER = gql`
  query getEnterpriseMember {
    getEnterpriseMember {
      id
      member {
        externalId
      }
    }
  }
`;

export {
  GET_ZIP_CODE_SUMMARY,
  GET_ENTERPRISE_ENROLLMENT_COMPONENTS,
  VERIFY_ENTERPRISE_EMPLOYEE_ELIGIBILITY,
  SAVE_ENTERPRISE_SEEKER_SERVICE_INTERESTS,
  ENROLL_ENTERPRISE_EMPLOYEE,
  GET_ENTERPRISE_BRANDED_CONTENT,
  ENROLL_EXISTING_SEEKER_TO_ENTERPRISE_CLIENT,
  GET_SSO_DATA,
  IS_ACTIVE_MEMBER_FOR_GROUP,
  GET_ENTERPRISE_LIFE_CARE_ACCOUNT_VERIFICATION,
  LIFE_CARE_LOGIN,
  LIFE_CARE_OTP_CREATE,
  FIND_CARE_GROUPS_BY_PARTIAL_NAME,
  CHANGE_MEMBER_PASSWORD,
  VERIFY_EMPLOYEE_EXISTS_BY_EMAIL,
  GET_ENTERPRISE_EMPLOYEE_VERIFICATION_RECORD,
  GET_ENTERPRISE_COUNTRY_OPTIONS,
  GET_ENTERPRISE_MEMBER,
  ENTERPRISE_CHECK_ENROLLMENT_CONFLICT_BY_EMAIL,
};
